import React from 'react';
import { Link } from "gatsby";

export default function About() {
    return (
        <div>
            <Link to="/weather" color="secondary">
                Go to the weather page
            </Link>
            <Link to="/" color="secondary">
                Go to the main page
            </Link>
        </div>
    );
}
